"use client";

import { asConvertedCurrency } from "@/common/utilities/math/asConvertedCurrency";
import { asCurrency } from "@/common/utilities/math/asCurrency";
import { Dinero } from "dinero.js";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

interface ConvertedCurrencyProps {
    amount: number;
    currency: string;
    toCurrency: string;
    onDate?: DateTime;
    render: (
        convertedAmount: Dinero,
        converted: boolean,
        rate: number
    ) => React.ReactNode;
}

export const ConvertedCurrency: React.FC<ConvertedCurrencyProps> = ({
    amount,
    currency,
    toCurrency,
    onDate,
    render,
}) => {
    const [dinero, setDinero] = useState<Dinero>(asCurrency(amount, currency));

    useEffect(() => {
        void asConvertedCurrency(amount, currency, toCurrency, onDate).then(
            setDinero
        );
    }, [amount, currency, toCurrency, onDate]);
    const converted =
        dinero.getCurrency() === toCurrency && currency !== toCurrency;
    const rate = dinero.getAmount() / amount;

    return render(dinero, converted, rate);
};
