"use client";

import {
    lngLatBoundsToViewBounds,
    TViewBoundsSchema,
} from "@/common/domain/entities/locations/ViewBoundsSchema";
import {
    ReusableMapInitialView,
    ReusableMapOptions,
    ReusableMapStoreProvider,
    useReusableMapStore,
} from "@/component-library/components/map-reusable/createReusableMapStore";
import { stylex } from "@/component-library/utilities/stylex";
import { FilteringConfig } from "@/configs/discover/FilteringConfig";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { GeographicLocation } from "@/features/host-locations/domain/entities/GeographicLocation";
import { MapState } from "@/features/host-locations/domain/entities/MapState";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import mapbox, { MapboxEvent } from "mapbox-gl";
import React, {
    CSSProperties,
    forwardRef,
    memo,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import {
    Map,
    MapProps,
    MapRef,
    NavigationControl,
    GeolocateControl,
    ViewStateChangeEvent,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDebounceCallback } from "usehooks-ts";
import "./ReusableMap.css";

export type TMapStateWithBounds = MapState & {
    viewBounds: TViewBoundsSchema;
};

export interface ReusableMapImperativeHandle {
    __map: mapbox.Map | null;
    moveTo: (position: GeographicLocation, zoom?: number) => Promise<void>;
    setTo: (position: GeographicLocation, zoom?: number) => Promise<void>;
    resize: VoidFunction;
    zoomToBounds: (props: {
        topLeftCorner: GeographicCoordinate;
        bottomRightCorner: GeographicCoordinate;
        options?: {
            animate?: boolean;
            duration?: number;
            padding?: number;
        };
    }) => void;
}

export interface ReusableMapProps {
    children?: ReactNode;

    mapOptions: ReusableMapOptions;
    initialView: ReusableMapInitialView;

    style?: CSSProperties;

    onLoad?: () => void;
    onResize?: (map: mapbox.Map) => void;

    /**
     * HAS TO be a memoized callback. Otherwise, the reference to the function changes on
     * every component re-render, and debouncing within the component won't work.
     */
    onMapStateChange?: (mapState: TMapStateWithBounds) => void;

    rawMapProps?: Omit<MapProps, "onResize">;
}

export const InnerReusableMap = forwardRef<
    ReusableMapImperativeHandle,
    ReusableMapProps
>(
    (
        {
            children,
            mapOptions,
            initialView,
            onMapStateChange: _onMapStateChange,
            onLoad,
            onResize,
            style,
            rawMapProps,
        },
        refIn
    ) => {
        const mapRef = useRef<MapRef | null>(null);

        const setMapState = useReusableMapStore((state) => state.setMapState);
        const setMapBounds = useReusableMapStore((state) => state.setMapBounds);

        const initialViewState = useMemo<MapProps["initialViewState"]>(() => {
            return {
                latitude: initialView.initialLocation.latitude,
                longitude: initialView.initialLocation.longitude,
                zoom: initialView.initialZoom,
            };
            // Initial view state only used on first render; subsequently it is ignored. Thus,
            // we do not need to react to changes to its dependencies.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const wrappedOnMapStateChange = useCallback(
            (mapState: TMapStateWithBounds) => {
                _onMapStateChange?.(mapState);
            },
            [_onMapStateChange]
        );

        const debouncedOnMapStateChange = useDebounceCallback(
            wrappedOnMapStateChange,
            FilteringConfig.updateDelayAfterFiltersChange
        );

        const updateMapStoreStateAndDebounceUpdatesToParent = useCallback(
            (mapState: TMapStateWithBounds) => {
                setMapState(mapState);
                setMapBounds(mapState.viewBounds);

                debouncedOnMapStateChange(mapState);
            },
            [debouncedOnMapStateChange, setMapBounds, setMapState]
        );

        const moveTo = useCallback(
            async (position: GeographicLocation, zoom?: number) => {
                if (!mapRef.current) {
                    return;
                }

                mapRef.current.flyTo({
                    center: position.getCoordinateAsLngLat(),
                    zoom: zoom ?? 8,
                    duration: mapOptions.mapConfig.flyToSpeedInMs,
                });
            },
            [mapRef, mapOptions.mapConfig.flyToSpeedInMs]
        );

        const setTo = useCallback(
            async (position: GeographicLocation, zoom?: number) => {
                if (!mapRef.current) {
                    return;
                }
                mapRef.current.setCenter(position.getCoordinateAsLngLat());
                mapRef.current.setZoom(zoom ?? 8);
            },
            [mapRef]
        );

        const resize = useCallback(() => {
            if (!mapRef.current) {
                return;
            }

            void onResize?.(mapRef.current.resize());
        }, [mapRef, onResize]);

        const zoomToBounds = useCallback<
            ReusableMapImperativeHandle["zoomToBounds"]
        >(
            ({ topLeftCorner, bottomRightCorner, options }) => {
                if (!mapRef.current) {
                    return;
                }

                mapRef.current.fitBounds(
                    [
                        topLeftCorner.getCoordinateAsLngLat(),
                        bottomRightCorner.getCoordinateAsLngLat(),
                    ],
                    {
                        animate: options?.animate ?? false,
                        duration: options?.duration ?? undefined,
                        padding: options?.padding ?? 0,
                    }
                );
            },
            [mapRef]
        );

        const wrappedOnLoad = useCallback(
            (e: MapboxEvent) => {
                onLoad?.();

                updateMapStoreStateAndDebounceUpdatesToParent({
                    currentPosition: new GeographicCoordinate({
                        latitude: e.target.getCenter().lat,
                        longitude: e.target.getCenter().lng,
                    }),
                    zoom: e.target.getZoom(),
                    viewBounds: lngLatBoundsToViewBounds(e.target.getBounds()),
                });
            },
            [onLoad, updateMapStoreStateAndDebounceUpdatesToParent]
        );

        const onMove = useCallback(
            (e: ViewStateChangeEvent) => {
                const finalLatitude = e.viewState.latitude;
                const finalLongitude = e.viewState.longitude;

                const mapState = {
                    zoom: e.viewState.zoom,
                    currentPosition: new GeographicCoordinate({
                        latitude: finalLatitude,
                        longitude: finalLongitude,
                    }),
                };
                const mapBounds = lngLatBoundsToViewBounds(
                    e.target.getBounds()
                );

                updateMapStoreStateAndDebounceUpdatesToParent({
                    ...mapState,
                    viewBounds: mapBounds,
                });
            },
            [updateMapStoreStateAndDebounceUpdatesToParent]
        );

        useImperativeHandle(
            refIn,
            () => {
                return {
                    __map: mapRef.current?.getMap() ?? null,
                    moveTo,
                    setTo,
                    resize,
                    zoomToBounds,
                };
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [moveTo, resize, zoomToBounds, mapRef.current]
        );

        useEffect(() => {
            if (!mapRef.current) {
                return;
            }

            const observer = new ResizeObserver(() => {
                resize();
            });

            observer.observe(mapRef.current.getContainer());

            return () => {
                observer.disconnect();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [resize, mapRef.current]);

        return (
            <Map
                ref={mapRef}
                key="map"
                onLoad={wrappedOnLoad}
                onMove={onMove}
                initialViewState={initialViewState}
                mapboxAccessToken={mapOptions.mapConfig.accessToken}
                mapStyle={mapOptions.mapConfig.stylesUrl}
                style={stylex(
                    assignInlineVars({
                        width: `100%`,
                        height: `100%`,

                        zIndex: `0`,
                    }),
                    style
                )}
                projection={{ name: "mercator" }}
                minZoom={mapOptions.minZoom ?? undefined}
                maxZoom={mapOptions.maxZoom ?? undefined}
                dragRotate={false}
                pitchWithRotate={false}
                scrollZoom={!mapOptions.useOnlyZoomButtons}
                touchZoomRotate={true}
                antialias={false}
                interactive={mapOptions.isInteractive}
                {...rawMapProps}
            >
                <NavigationControl position="bottom-left" />
                <GeolocateControl
                    position="bottom-left"
                    showUserLocation={true}
                />
                {children}
            </Map>
        );
    }
);

InnerReusableMap.displayName = "InnerReusableMap";

const ReusableMap = forwardRef<
    ReusableMapImperativeHandle,
    Omit<ReusableMapProps, "store">
>((props, refIn) => {
    return (
        <ReusableMapStoreProvider
            key="reusable-map-store-provider"
            mapOptions={props.mapOptions}
            initialView={props.initialView}
        >
            <InnerReusableMap ref={refIn} key="reusable-map" {...props} />
        </ReusableMapStoreProvider>
    );
});

ReusableMap.displayName = "ReusableMap";

export default memo(ReusableMap);
