import { whiteColor } from "@/component-library/themes/themes/lightTheme";
import React, { forwardRef, HTMLAttributes } from "react";

interface MapMarkerIconProps extends HTMLAttributes<SVGSVGElement> {
    pathProps?: HTMLAttributes<SVGPathElement>;

    width?: string;
    height?: string;
    fill?: string;
}

const MapMarkerIcon = forwardRef<SVGSVGElement, MapMarkerIconProps>(
    ({ pathProps = {}, ...restProps }, refIn) => {
        return (
            <svg
                ref={refIn}
                width={`12px`}
                height={`22px`}
                viewBox="4 1.5 16 21"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                {...restProps}
            >
                <path
                    fill="#000000"
                    fillRule="evenodd"
                    d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    clipRule="evenodd"
                    stroke={whiteColor.alpha(0.25).toString()}
                    strokeWidth={1}
                    {...pathProps}
                />
            </svg>
        );
    }
);
MapMarkerIcon.displayName = "MapMarkerIcon";

export default MapMarkerIcon;
