/**
 *
 * @param nodeWhoseAscendantsToCheck
 * @param callback - return false to stop iterating
 */
export const loopThroughAscendants = (
    nodeWhoseAscendantsToCheck: HTMLElement,
    callback: (ascendant: HTMLElement) => boolean
) => {
    let ascendant = nodeWhoseAscendantsToCheck.parentElement;

    // keep iterating unless null
    while (ascendant != null) {
        if (!callback(ascendant)) {
            return;
        }

        ascendant = ascendant.parentElement;
    }
};
