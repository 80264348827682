"use client";

import { hasValue } from "@/common/utilities/hasValue";
import { useValueRef } from "@/component-library/animation/useValueRef";
import {
    tooltipContentsStyle,
    tooltipContentsWrapperStyle,
    tooltipIconStyle,
    tooltipPostfixStyle,
    tooltipPrefixStyle,
    tooltipStyle,
} from "@/component-library/components/feedback/tooltip/TooltipStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { ZIndex } from "@/component-library/constants/ZIndex";
import { stylex } from "@/component-library/utilities/stylex";
import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, {
    CSSProperties,
    ElementType,
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
    JSX,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { animated, useSpring } from "react-spring";
import { useDebounceCallback, useIsomorphicLayoutEffect } from "usehooks-ts";

interface TooltipProps<TAs extends keyof JSX.IntrinsicElements>
    extends Omit<HTMLAttributes<TAs>, "prefix"> {
    as?: TAs;

    children?: ReactNode;

    tooltip?: ReactNode;

    prefix?: ReactNode;
    postfix?: ReactNode;
    icon?: ReactNode;

    disabled?: boolean;

    centerTooltipHorizontally?: boolean;
    removeOverlayStyles?: boolean;

    delayDurationInMs?: number;

    onShow?: () => void;
    onHide?: () => void;
}

const Tooltip = <TAs extends keyof JSX.IntrinsicElements>(
    {
        as = "div" as TAs,
        children,
        tooltip,
        prefix,
        postfix,
        icon,
        disabled = false,
        className,
        style,
        removeOverlayStyles,
        delayDurationInMs = 100,
        onShow,
        onHide,
        ...restProps
    }: TooltipProps<TAs>,
    refIn: ForwardedRef<HTMLDivElement | null>
) => {
    const {
        ref: isOpenRef,
        state: isOpen,
        set: _setIsOpen,
    } = useValueRef(false);
    const [isMounted, setIsMounted] = useState(false);

    const setIsOpen = useCallback(
        (newIsOpen: boolean) => {
            if (isOpenRef.current === newIsOpen) {
                return;
            }

            _setIsOpen(newIsOpen);
            if (newIsOpen) {
                onShow?.();
            } else {
                onHide?.();
            }
        },
        [_setIsOpen, isOpenRef, onHide, onShow]
    );

    const debouncedSetIsOpen = useDebounceCallback(
        setIsOpen,
        delayDurationInMs
    );

    const setIsOpenWhereTrueIsDebouncedButFalseImmediate = useCallback(
        (newIsOpen: boolean) => {
            if (newIsOpen) {
                debouncedSetIsOpen(true);
            } else {
                debouncedSetIsOpen(false);
                setIsOpen(false);
            }
        },
        [debouncedSetIsOpen, setIsOpen]
    );

    const { refs, floatingStyles, context } = useFloating({
        strategy: "absolute",
        placement: "bottom",
        open: isOpen && !disabled,
        onOpenChange: setIsOpenWhereTrueIsDebouncedButFalseImmediate,
        middleware: [
            flip(),
            shift(),
            offset({
                mainAxis: 6,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const [rootElement, setRootElement] = useState<HTMLBodyElement | null>(
        null
    );

    const As = useMemo(() => as as ElementType, [as]);

    const tooltipContents = useMemo(() => {
        return (
            <>
                {icon && <div className={tooltipIconStyle}>{icon}</div>}
                {tooltip}
            </>
        );
    }, [icon, tooltip]);

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);

    const role = useRole(context, {
        role: "tooltip",
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role,
    ]);

    const referenceProps = useMemo(
        () => getReferenceProps(),
        [getReferenceProps]
    );
    const floatingProps = useMemo(() => getFloatingProps(), [getFloatingProps]);

    useImperativeHandle(refIn, () => refs.reference.current as HTMLDivElement, [
        refs.reference,
    ]);

    useIsomorphicLayoutEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (document.body === undefined) {
            return;
        }

        setRootElement(document.body as HTMLBodyElement);
    }, []);

    const animationConfig = useMemo(() => {
        return { duration: Magnitudes.durationsInS.xs * 1000 };
    }, []);

    const [animationStyles, animationApi] = useSpring(() => ({
        from: {
            opacity: 0,
        },
        config: animationConfig,
    }));

    useLayoutEffect(() => {
        if (!isMounted) {
            return;
        }

        void Promise.all(
            animationApi.start({
                to: {
                    opacity: isOpen ? 1 : 0,
                },
                config: animationConfig,
            })
        ).then(() => {
            if (!isOpen) {
                setIsMounted(false);
            }
        });
    }, [animationApi, animationConfig, isMounted, isOpen]);

    useEffect(() => {
        if (isOpen) {
            setIsMounted(true);
        }
    }, [isOpen]);

    return (
        <>
            <As
                ref={refs.setReference}
                {...referenceProps}
                className={clsx(
                    className,
                    referenceProps["className"] as string
                )}
                style={stylex(style, referenceProps["style"] as CSSProperties)}
                {...restProps}
            >
                {children}
            </As>

            {hasValue(rootElement) && hasValue(tooltip) && isMounted && (
                <FloatingPortal root={rootElement}>
                    <animated.div
                        ref={refs.setFloating}
                        {...floatingProps}
                        className={clsx(
                            !removeOverlayStyles && tooltipStyle,
                            floatingProps["className"] as string
                        )}
                        style={stylex(
                            animationStyles,
                            floatingStyles,
                            floatingProps["style"] as CSSProperties,
                            assignInlineVars({
                                zIndex: `${ZIndex.overlay + 1000}`,
                            })
                        )}
                    >
                        <div className={tooltipContentsWrapperStyle}>
                            <div
                                className={clsx(
                                    !removeOverlayStyles && tooltipContentsStyle
                                )}
                            >
                                {prefix && (
                                    <div className={tooltipPrefixStyle}>
                                        {prefix}
                                    </div>
                                )}
                                {tooltipContents}
                            </div>
                            {postfix && (
                                <div className={tooltipPostfixStyle}>
                                    {postfix}
                                </div>
                            )}
                        </div>
                    </animated.div>
                </FloatingPortal>
            )}
        </>
    );
};

export default forwardRef(Tooltip);
