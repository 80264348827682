import { loopThroughAscendants } from "@/common/application/components/loopThroughAscendants";
import { ignore } from "@/common/application/ignore";
import { hasValue } from "@/common/utilities/hasValue";
import useEnterExitScaleAnimation from "@/component-library/animation/application/useEnterExitScaleAnimation";
import Tooltip from "@/component-library/components/feedback/tooltip/Tooltip";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import { PositionRelativeToWrappedHandle } from "@/component-library/components/layout/position-relative-to-wrapped/PositionRelativeToWrapped";
import { useReusableMapStore } from "@/component-library/components/map-reusable/createReusableMapStore";
import {
    reusableMapMarkerStyle,
    reusableMapMarkerSvgStyle,
    reusableMapMarkerWithChildrenStyle,
} from "@/component-library/components/map-reusable/ReusableMapMarkerStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { ZIndex } from "@/component-library/constants/ZIndex";
import { theme } from "@/component-library/themes/theme.css";
import { stylex } from "@/component-library/utilities/stylex";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import mapboxgl from "mapbox-gl";
import React, {
    HTMLProps,
    ReactNode,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
} from "react";
import { Marker, MarkerProps } from "react-map-gl";
import { Transition } from "react-transition-group";
import { useOnClickOutside } from "usehooks-ts";

interface ReusableMapMarkerProps
    extends Omit<MarkerProps, "latitude" | "longitude"> {
    children?: ReactNode;

    coordinate: GeographicCoordinate;
    isShowing: boolean;

    isInactive?: boolean;

    tooltip?: ReactNode;
    tooltipPrefix?: ReactNode;
    tooltipPostfix?: ReactNode;
    removeTooltipStyles?: boolean;

    fillColor?: string;
    inactiveFillColor?: string;
    size?: "medium" | "large";

    zIndex?: number;
    inactiveZIndex?: number;

    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onShowTooltip?: () => void;
    onHideTooltip?: () => void;
    onClickOutside?: () => void;
    ignoreOnClickOutsideWhenClickedElementContainsClass?: string[];

    linkProps?: HTMLProps<HTMLAnchorElement>;
}

const ReusableMapMarker: React.FC<ReusableMapMarkerProps> = ({
    children,
    tooltip,
    tooltipPrefix,
    tooltipPostfix,
    removeTooltipStyles,
    coordinate,
    isShowing,
    isInactive,
    fillColor = theme.colors.semantic.interactive.default,
    inactiveFillColor = theme.colors.semantic.interactive.veryLightDesaturated,
    size = "medium",
    zIndex = 0,
    inactiveZIndex = ZIndex.overlay,
    onMouseEnter,
    onMouseLeave,
    onShowTooltip,
    onHideTooltip,
    onClickOutside,
    ignoreOnClickOutsideWhenClickedElementContainsClass = [],
    linkProps,
    ...restProps
}) => {
    const mapState = useReusableMapStore((state) => state.mapState);

    const positionerRef = useRef<PositionRelativeToWrappedHandle | null>(null);
    const markerRef = useRef<mapboxgl.Marker>(null);
    const customMarkerRef = useRef<HTMLDivElement | null>(null);
    const markerSvgRef = useRef<SVGSVGElement>(null);

    const animationDuration = useMemo(() => {
        return {
            durationInS: Magnitudes.durationsInS.m,
            delayInS: Math.random() * Magnitudes.durationsInS.xxxl,
            // Duration + delay:
            maxInS: Magnitudes.durationsInS.m + Magnitudes.durationsInS.s,
        };
    }, []);
    const { appear, disappear } = useEnterExitScaleAnimation({
        element: markerSvgRef,
        durationInS: animationDuration.durationInS,
        delayInS: animationDuration.delayInS,
        initialState: isShowing ? "in" : "out",
        debounceDurationInMs: animationDuration.maxInS * 1000,
    });

    useLayoutEffect(() => {
        ignore(mapState?.currentPosition);
        positionerRef.current?.updatePosition();
    }, [mapState?.currentPosition]);

    const renderedChildren = useMemo(() => {
        const opacity = `${isInactive ? 0.8 : 1}`;
        const primaryColor = isInactive ? inactiveFillColor : fillColor;

        let sizeInPx: number;
        switch (size) {
            case "medium":
                sizeInPx = 38;
                break;
            case "large":
                sizeInPx = 48;
                break;
        }

        if (hasValue(children)) {
            return (
                <Tooltip
                    ref={customMarkerRef}
                    tooltip={tooltip}
                    postfix={tooltipPostfix}
                    prefix={tooltipPrefix}
                    removeOverlayStyles={removeTooltipStyles}
                    className={clsx(reusableMapMarkerWithChildrenStyle)}
                    style={stylex(
                        assignInlineVars({
                            opacity,
                            backgroundColor: primaryColor,
                            zIndex: `${isInactive ? inactiveZIndex : zIndex}`,
                        })
                    )}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onShow={onShowTooltip}
                    onHide={onHideTooltip}
                >
                    {children}
                </Tooltip>
            );
        }

        // const zoom = mapState?.zoom ?? 6;
        const scale = 1; //interpolateBetweenTwoValues(9, 12, 0.75, 1.2, zoom);

        return (
            <div
                ref={customMarkerRef}
                className={clsx(reusableMapMarkerStyle)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Tooltip
                    tooltip={tooltip}
                    postfix={tooltipPostfix}
                    prefix={tooltipPrefix}
                    removeOverlayStyles={removeTooltipStyles}
                    onShow={onShowTooltip}
                    onHide={onHideTooltip}
                >
                    <MapMarkerIcon
                        ref={markerSvgRef}
                        width={`${sizeInPx * scale}px`}
                        height={`${sizeInPx * scale}px`}
                        className={clsx(reusableMapMarkerSvgStyle)}
                        onMouseEnter={() => positionerRef.current?.show()}
                        onMouseLeave={() => positionerRef.current?.hide()}
                        pathProps={{
                            style: assignInlineVars({
                                fill: primaryColor,

                                opacity,

                                transition: `fill ${Magnitudes.durationsInS.s}s ease-out`,
                            }),
                        }}
                    />
                </Tooltip>
            </div>
        );
    }, [
        children,
        fillColor,
        inactiveFillColor,
        inactiveZIndex,
        isInactive,
        onHideTooltip,
        onMouseEnter,
        onMouseLeave,
        onShowTooltip,
        removeTooltipStyles,
        size,
        tooltip,
        tooltipPostfix,
        tooltipPrefix,
        zIndex,
        mapState,
    ]);

    const markerHtmlElementPseudoRef = useMemo(() => {
        return {
            current: markerRef.current?.getElement() ?? null,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markerRef.current]);

    const onClickOutsideWrapper = useCallback(
        (event: MouseEvent | TouchEvent | FocusEvent) => {
            let shouldTriggerOnClickOutside = true;

            loopThroughAscendants(event.target as HTMLElement, (ascendant) => {
                let ascendantContainsClass = false;

                for (const cssClass of ignoreOnClickOutsideWhenClickedElementContainsClass) {
                    ascendantContainsClass =
                        ascendant.classList.contains(cssClass);

                    if (ascendantContainsClass) {
                        shouldTriggerOnClickOutside = false;
                        break;
                    }
                }

                return shouldTriggerOnClickOutside;
            });

            if (shouldTriggerOnClickOutside) {
                onClickOutside?.();
            }
        },
        [ignoreOnClickOutsideWhenClickedElementContainsClass, onClickOutside]
    );

    useOnClickOutside(
        markerHtmlElementPseudoRef,
        onClickOutsideWrapper,
        "mousedown"
    );
    useOnClickOutside(
        markerHtmlElementPseudoRef,
        onClickOutsideWrapper,
        "touchstart"
    );

    return (
        <Transition
            nodeRef={markerRef.current as any}
            timeout={animationDuration.maxInS * 1000}
            onExit={() => {
                disappear();
            }}
            onEnter={() => {
                appear();
            }}
            in={isShowing}
        >
            <Marker
                ref={markerRef}
                latitude={coordinate.latitude}
                longitude={coordinate.longitude}
                style={assignInlineVars({
                    zIndex: `${isInactive ? inactiveZIndex : zIndex}`,
                })}
                {...restProps}
            >
                {linkProps ? (
                    <a {...linkProps}>{renderedChildren}</a>
                ) : (
                    renderedChildren
                )}
            </Marker>
        </Transition>
    );
};

export default ReusableMapMarker;
