import { buildErrorWithPropsAndPayload } from "@/common/application/errors/error-builders";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";
import { z } from "zod";

export const InvalidGeographicCoordinateError = buildErrorWithPropsAndPayload({
    errorName: "InvalidGeographicCoordinateError",
    payload: z.union([z.string(), z.array(z.any())]),
    builder: UserFacingErrorBuilder,
    build: (
        builder,
        props: {
            rawCoordinates: string | any[];
        }
    ) =>
        builder
            .enableAnalytics()
            .setInternalMessage(
                `[${props.rawCoordinates}] could not be parsed into a [GeographicCoordinate].`
            )
            .setUserFacingErrorMessage(
                `The provided coordinates are not valid: ${props.rawCoordinates}.`
            )
            .addPayload(props.rawCoordinates),
});
