import { hasValue } from "@/common/utilities/hasValue";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDebounceCallback } from "usehooks-ts";

interface UseEnterExitAnimationHookProps<TElement extends HTMLOrSVGElement> {
    element: React.RefObject<TElement | null>;
    durationInS?: number;
    delayInS?: number;

    initialStyles?: Partial<CSSStyleDeclaration>;

    inVars: GSAPTweenVars;
    outVars: GSAPTweenVars;

    initialState: "in" | "out";

    displayNoneWhenUnmounted?: boolean;

    debounceDurationInMs?: number;
}

const useEnterExitAnimationHook = <TElement extends HTMLElement | SVGElement>({
    element,
    durationInS = Magnitudes.durationsInS.m,
    delayInS = 0,
    initialStyles,
    inVars,
    outVars,
    initialState,
    displayNoneWhenUnmounted = true,
    debounceDurationInMs = 300,
}: UseEnterExitAnimationHookProps<TElement>) => {
    const [state, setState] = useState<"in" | "out">(initialState);
    const [hasInitialPropertiesSet, setHasInitialPropertiesSet] =
        useState(false);

    /**
     * Needed, otherwise debounce only delays the calls by the delay, but does not batch them.
     */
    const staticSetState = useCallback((value: "in" | "out") => {
        return setState(value);
    }, []);

    const debouncedSetState = useDebounceCallback(
        staticSetState,
        debounceDurationInMs
    );

    useEffect(() => {
        if (!element.current || !initialStyles || hasInitialPropertiesSet) {
            return;
        }

        if (displayNoneWhenUnmounted) {
            element.current.style["display"] = `none`;
        }

        for (const [key, value] of Object.entries(initialStyles)) {
            if (!hasValue(value)) {
                return;
            }

            element.current.style[key as any] = `${value}`;
        }

        setHasInitialPropertiesSet(true);
    }, [element, hasInitialPropertiesSet, initialStyles]);

    const timelineVars = useMemo<GSAPTimelineVars>(() => {
        return {
            defaults: {
                duration: durationInS,
                delay: delayInS,
            },
        };
    }, [delayInS, durationInS]);

    const timeline = useRef<ReturnType<typeof gsap.timeline> | null>(null);

    const appear = useCallback(() => {
        debouncedSetState("in");
    }, [debouncedSetState]);

    const disappear = useCallback(() => {
        debouncedSetState("out");
    }, [debouncedSetState]);

    const actions = useMemo(() => {
        return {
            appear,
            disappear,
        };
    }, [appear, disappear]);

    useGSAP(
        () => {
            if (!element.current) {
                return;
            }

            if (state === "in") {
                timeline.current = gsap
                    .timeline(timelineVars)
                    .to(element.current, {
                        ...inVars,
                        onStart: () => {
                            if (element.current) {
                                element.current.style.display = "block";
                            }
                        },
                    });
            } else {
                timeline.current = gsap
                    .timeline(timelineVars)
                    .to(element.current, {
                        ...outVars,
                        onComplete: () => {
                            if (element.current && displayNoneWhenUnmounted) {
                                element.current.style.display = "none";
                            }
                        },
                    });
            }

            return () => {
                timeline.current?.revert();
            };
        },
        {
            dependencies: [element.current, state, displayNoneWhenUnmounted],
        }
    );

    return actions;
};

export default useEnterExitAnimationHook;
