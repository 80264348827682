"use client";

import { ConvertedCurrency } from "@/component-library/components/currency/converted-currency/ConvertedCurrency";
import {
    convertedCurrencyComponentTooltipContentCss,
    convertedCurrencyComponentTooltipCss,
    convertedCurrencyComponentTooltipTitleCss,
} from "@/component-library/components/currency/converted-currency/ConvertedCurrencyComponent.css";
import Tooltip from "@/component-library/components/feedback/tooltip/Tooltip";
import { pricingFilterCurrency } from "@/features/filtering/filters/pricing-filter/pricing-filte-query-keys";
import { Dinero } from "dinero.js";
import { DateTime } from "luxon";
import { useQueryState } from "nuqs";
import React, { FC } from "react";

const toConversionInformaton = (
    currency: string,
    toCurrency: string,
    rate: number,
    convertedAmount: Dinero,
    onDate?: DateTime
) => (
    <div className={convertedCurrencyComponentTooltipCss}>
        <h4 className={convertedCurrencyComponentTooltipTitleCss}>
            Currency conversion information
        </h4>
        <div className={convertedCurrencyComponentTooltipContentCss}>
            The price is indicative, based on the{" "}
            {onDate
                ? `exchange rate on ${onDate.toLocaleString()}`
                : "current exchange rate"}{" "}
            of{" "}
            <strong>
                1 {currency} = {rate.toFixed(2)} {toCurrency}
            </strong>
            . You{" "}
            {onDate
                ? `have been charged ${convertedAmount.toFormat()}.`
                : `will pay the in ${currency} at checkout.`}
        </div>
        <div className={convertedCurrencyComponentTooltipContentCss}>
            <strong>
                Your bank may charge a different rate and a fee for currency
                conversion.
            </strong>
        </div>
    </div>
);

interface ConvertedCurrencyComponentsProps {
    amount: number;
    currency: string;
    postFix?: string;
    onDate?: DateTime;
    enableTooltip?: boolean;
}

export const ConvertedCurrencyComponent: FC<
    ConvertedCurrencyComponentsProps
> = ({ amount, currency, postFix, onDate, enableTooltip }) => {
    const [toCurrency] = useQueryState(pricingFilterCurrency);
    const toCurrencyValue = toCurrency || "EUR";

    return (
        <ConvertedCurrency
            amount={amount}
            currency={currency}
            toCurrency={toCurrencyValue}
            onDate={onDate}
            render={(convertedAmount, converted, rate) => (
                <Tooltip
                    tooltip={
                        converted && enableTooltip
                            ? toConversionInformaton(
                                  currency,
                                  toCurrencyValue,
                                  rate,
                                  convertedAmount,
                                  onDate
                              )
                            : undefined
                    }
                >
                    {convertedAmount.toFormat()}
                    {converted && enableTooltip ? "*" : ""}
                    {postFix}
                </Tooltip>
            )}
        />
    );
};
