export class ZIndex {
    static readonly contentBehind = 2100;
    static readonly contentBackground = 2200;
    static readonly contentForeground = 2300;

    static readonly appBar = 5000;

    static readonly overlay = 4000;

    static readonly notification = 6000;

    static readonly alert = 7000;

    private constructor() {}
}
