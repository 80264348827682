"use client";

import {
    imageBreathingLayerStyle,
    remoteImageOverlayStyle,
    remoteImageStyle,
    remoteImageWrapperStyle,
} from "@/component-library/components/media/remote-image/RemoteImageStyles.css";
import { skeletonStyle } from "@/component-library/components/skeleton-style.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { ImageRenderResolution } from "@/component-library/domain/entities/ImageResolution";
import { stylex } from "@/component-library/utilities/stylex";
import { NetworkEndpoints } from "@/configs/NetworkEndpoints";
import { TPictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import Image from "next/image";
import React, {
    forwardRef,
    ReactNode,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { animated, useSpring } from "react-spring";

interface RemoteImageProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
    pictureData?: TPictureSchema;
    fallbackCaption: string;

    priority?: boolean;
    quality?: number;
    imageResolution: ImageRenderResolution;
    placeholderImage?: ReactNode;

    imageClassName?: string;

    keepInLoadingState?: boolean;
}

const animationConfig = { duration: Magnitudes.durationsInS.l * 1000 };

/**
 * Shows a shimmer when loading an image.
 * @param src - URL to the image/
 * @param alt - Alternative text for screen readers.
 * @param className - Optional CSS classes.
 * @param style - Optional inline styles.
 * @param imageResolution - Resolution of the image to show.
 * @param restProps - Other [div] customisations for the wrapper that wraps the image and
 * determines its size etc.
 * @constructor
 */
const RemoteImage = forwardRef<HTMLImageElement | undefined, RemoteImageProps>(
    (
        {
            pictureData,
            fallbackCaption,
            className,
            priority,
            imageResolution,
            imageClassName,
            quality,
            keepInLoadingState,
            ...restProps
        },
        refIn
    ) => {
        const imageRef = useRef<HTMLImageElement | null>(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useImperativeHandle(refIn, () => imageRef.current ?? undefined, [
            imageRef,
            pictureData,
        ]);

        const src = pictureData
            ? pictureData.url ||
              NetworkEndpoints.imageUrl(
                  pictureData.id,
                  imageResolution.widthInPx * 2,
                  imageResolution.heightInPx * 2,
                  100
              )
            : undefined;
        const caption = pictureData?.caption ?? fallbackCaption;
        const [isImageLoaded, setIsImageLoaded] = useState(false);
        const [isImageLoading, setIsImageLoading] = useState(true);

        const [breathingAnimationStyles, breathingAnimationApi] = useSpring(
            () => ({
                from: {
                    opacity: 0,
                },
                config: animationConfig,
            })
        );

        useLayoutEffect(() => {
            const opacity =
                (isImageLoading && !isImageLoaded) || keepInLoadingState
                    ? 1
                    : 0;
            void breathingAnimationApi.start({
                to: {
                    opacity: opacity,
                },
                config: animationConfig,
            });
        }, [
            breathingAnimationApi,
            isImageLoading,
            isImageLoaded,
            keepInLoadingState,
        ]);

        return (
            <div
                className={clsx(remoteImageWrapperStyle, className)}
                {...restProps}
            >
                <div
                    style={stylex(
                        //imageAnimationStyles,
                        assignInlineVars({
                            width: `100%`,
                            height: `100%`,
                        })
                    )}
                >
                    {src && (
                        <Image
                            ref={imageRef}
                            className={clsx(remoteImageStyle, imageClassName)}
                            src={src}
                            alt={caption}
                            width={imageResolution.widthInPx}
                            height={imageResolution.heightInPx}
                            quality={quality}
                            priority={priority}
                            sizes="100vw"
                            onLoad={async () => {
                                setIsImageLoaded(true);
                                setIsImageLoading(false);
                            }}
                        />
                    )}
                    <animated.div
                        style={stylex(
                            breathingAnimationStyles,
                            assignInlineVars({
                                width: `100%`,
                                height: `100%`,
                            })
                        )}
                        className={clsx(remoteImageOverlayStyle)}
                    >
                        <div
                            className={clsx(
                                skeletonStyle,
                                imageBreathingLayerStyle
                            )}
                        />
                    </animated.div>
                </div>
            </div>
        );
    }
);
RemoteImage.displayName = "RemoteImage";

export default RemoteImage;
