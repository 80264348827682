import { ClientSideAccessiblePublicEnvValues } from "@/common/domain/constants/env/ClientSideAccessiblePublicEnvValues";
import { MapConfig } from "@/component-library/components/map-reusable/MapConfig";

/**
 * Configuration for the Wezoo map. Values loaded from the env file.
 */
export const WezooMapConfig: MapConfig = {
    accessToken: ClientSideAccessiblePublicEnvValues.wezooMapboxAccessToken!,
    stylesUrl: ClientSideAccessiblePublicEnvValues.wezooMapboxStylesUrl!,
    stateUpdateThrottleInterval: 200,
    flyToSpeedInMs: 900,
};

export const WezooGeoConfig = {
    bboxFromPointOffset: 0.01,
};
