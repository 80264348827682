import { TViewBoundsSchema } from "@/common/domain/entities/locations/ViewBoundsSchema";
import { OutOfProviderError } from "@/common/domain/errors/development/OutOfProviderError";
import { hasValue } from "@/common/utilities/hasValue";
import { MapConfig } from "@/component-library/components/map-reusable/MapConfig";
import { WezooMapConfig } from "@/configs/discover/WezooMapConfig";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { GeographicLocation } from "@/features/host-locations/domain/entities/GeographicLocation";
import { MapState } from "@/features/host-locations/domain/entities/MapState";
import React, { memo, ReactNode, useState } from "react";
import { createStore, useStore } from "zustand";

export const AMSTERDAM_LONG_LAT = { longitude: 4.89707, latitude: 52.377956 };
export const DEFAULT_INITIAL_ZOOM = 6;

export interface ReusableMapOptions {
    isInteractive: boolean;
    minZoom: number | null;
    maxZoom: number | null;
    useOnlyZoomButtons: boolean;

    mapConfig: MapConfig;
}

export interface ReusableMapInitialView {
    /**
     * [latitude, longitude]
     */
    initialLocation: GeographicCoordinate;
    initialZoom: number;
}

export interface ReusableMapState {
    mapIsReady: boolean;

    mapState: MapState | null;
    mapViewBounds: TViewBoundsSchema | null;

    mapOptions: ReusableMapOptions;
    initialView: ReusableMapInitialView;
}

const initialState = (): ReusableMapState => ({
    mapIsReady: false,

    mapState: {
        zoom: DEFAULT_INITIAL_ZOOM,
        currentPosition: new GeographicCoordinate({
            latitude: AMSTERDAM_LONG_LAT.latitude,
            longitude: AMSTERDAM_LONG_LAT.longitude,
        }),
    },
    mapViewBounds: null,

    mapOptions: {
        isInteractive: true,

        minZoom: null,
        maxZoom: null,
        useOnlyZoomButtons: false,

        mapConfig: WezooMapConfig,
    },

    initialView: {
        initialLocation: new GeographicCoordinate({
            latitude: AMSTERDAM_LONG_LAT.latitude,
            longitude: AMSTERDAM_LONG_LAT.longitude,
        }),
        initialZoom: DEFAULT_INITIAL_ZOOM,
    },
});

export interface ReusableMapActions {
    setMapState: (mapState: MapState) => void;
    setMapBounds: (bounds: TViewBoundsSchema) => void;
    setMapLoaded: (mapLoaded: boolean) => void;

    snapMapTo: (position: GeographicLocation, zoom?: number) => Promise<void>;
}

export const createReusableMapStore = (
    options: ReusableMapOptions,
    initialView: ReusableMapInitialView
) => {
    return createStore<ReusableMapState & ReusableMapActions>((set) => ({
        ...initialState(),

        mapState: {
            currentPosition: initialView.initialLocation,
            zoom: initialView.initialZoom,
        },

        mapOptions: options,

        setMapState: (mapState) => {
            set(() => ({ mapState }));
        },
        setMapBounds: (bounds) => {
            set(() => ({ mapViewBounds: bounds }));
        },
        setMapLoaded: (mapIsReady: boolean) => {
            set({ mapIsReady });
        },
        snapMapTo: async (position: GeographicLocation, zoom?: number) => {
            set({
                mapState: {
                    zoom: zoom ?? DEFAULT_INITIAL_ZOOM,
                    currentPosition: position.getCoordinate(),
                },
            });
        },
    }));
};

export const ReusableMapStoreContext = React.createContext<ReturnType<
    typeof createReusableMapStore
> | null>(null);

export const useReusableMapStore = <U,>(
    selector: (state: ReusableMapState & ReusableMapActions) => U
) => {
    const store = React.useContext(ReusableMapStoreContext);
    if (!hasValue(store)) {
        throw OutOfProviderError({ context: "ReusableMapStoreContext" });
    }

    return useStore(store, (state) => selector(state));
};

const _ReusableMapStoreProvider = (props: {
    mapOptions: ReusableMapOptions;
    initialView: ReusableMapInitialView;
    children?: ReactNode;
}) => {
    const [store] = useState(
        createReusableMapStore(props.mapOptions, props.initialView)
    );

    return (
        <ReusableMapStoreContext.Provider value={store}>
            {props.children}
        </ReusableMapStoreContext.Provider>
    );
};

export const ReusableMapStoreProvider = memo(_ReusableMapStoreProvider);
