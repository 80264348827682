import { buildErrorWithProps } from "@/common/application/errors/error-builders";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";

export const OutOfProviderError = buildErrorWithProps({
    errorName: "OutOfProviderError",
    builder: UserFacingErrorBuilder,
    build: (
        builder,
        props: {
            context: string;
        }
    ) =>
        builder
            .enableAnalytics()
            .setInternalMessage(
                `Out of provider for [${props.context}] context.`
            )
            .setUserFacingErrorMessage(`Something unexpected happened.`),
});
