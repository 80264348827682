import useEnterExitAnimationHook from "@/component-library/animation/application/useEnterExitAnimationHook";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import React from "react";

interface UseScaleAnimationProps<TElement extends HTMLElement | SVGElement> {
    element: React.RefObject<TElement | null>;
    durationInS?: number;
    delayInS?: number;

    initialState?: "in" | "out";

    displayNoneWhenUnmounted?: boolean;

    debounceDurationInMs?: number;
}

const useEnterExitScaleAnimation = <TElement extends HTMLElement | SVGElement>({
    element,
    durationInS = Magnitudes.durationsInS.m,
    delayInS = 0,
    initialState = "in",
    displayNoneWhenUnmounted = true,
    debounceDurationInMs,
}: UseScaleAnimationProps<TElement>) => {
    return useEnterExitAnimationHook({
        element,
        initialState,
        initialStyles: {
            transform: `scale(0)`,
            transformOrigin: "center bottom",
        },
        inVars: {
            transform: `scale(1)`,
        },
        outVars: {
            transform: `scale(0)`,
        },
        durationInS,
        delayInS,
        displayNoneWhenUnmounted,
        debounceDurationInMs,
    });
};

export default useEnterExitScaleAnimation;
