import { reportError } from "@/common/application/debug";
import { UnexpectedError } from "@/common/domain/errors/development/UnexpectedError";
import { GeographicLocation } from "@/features/host-locations/domain/entities/GeographicLocation";
import { InvalidGeographicCoordinateError } from "@/features/host-locations/domain/errors/InvalidGeographicCoordinateError";
import { z } from "zod";

export const numberOfElementsInAGeographicCoordinate = 2;

export const GeographicCoordinateSchema = z.object({
    id: z.string().optional(),
    latitude: z.number(),
    longitude: z.number(),
});
export type GeographicCoordinateSchema = z.infer<
    typeof GeographicCoordinateSchema
>;

/**
 * Represents a geographic coordinate.
 */
export class GeographicCoordinate implements GeographicLocation {
    id: string;
    latitude: number;
    longitude: number;

    /**
     * Allows creating a [GeographicCoordinate] with numbers.
     * @param id uniquely defines the coordinate location.
     * @param latitude
     * @param longitude
     * @throws when the latitude or the longitude cannot be
     * parsed into floating point numbers.
     */
    constructor({ latitude, longitude }: GeographicCoordinateSchema);

    /**
     * Allows passing in an array of numbers or strings that will be parsed into
     * a
     * GeographicCoordinate. Only use this utility constructor when you have an
     * array and do not know for certain that it has two values (latitude,
     * longitude); this constructor handles error checking.
     * @param args where first element is the latitude and the second is the
     * longitude. Both should be of the same type.
     * @throws {InvalidGeographicCoordinateError} when the types of the
     *   latitude/longitude do not match, the strings cannot be converted into
     *   floating point numbers, or the array length is not
     *   [numberOfElementsInAGeographicCoordinate], i.e. 2.
     */
    constructor(...args: string[] | number[]);

    constructor(...args: any[]) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!args) {
            throw UnexpectedError(
                {
                    message:
                        "Constructor was called without arguments. This should not happen.",
                },
                GeographicCoordinate.name
            );
        }

        // Parse args according to their type
        if (typeof args.at(0) === "object") {
            const result = GeographicCoordinateSchema.safeParse(args.at(0));

            if (result.success) {
                this.latitude = result.data.latitude;
                this.longitude = result.data.longitude;
                this.id = generateCoordinateIdFromCoordinatePoints(
                    this.latitude,
                    this.longitude
                );

                return;
            }
        } else {
            const result = parseArrayInputIntoCoordinates(...args);
            if (result) {
                const [latitude, longitude] = result;

                this.latitude = latitude;
                this.longitude = longitude;
                this.id = generateCoordinateIdFromCoordinatePoints(
                    latitude,
                    longitude
                );

                return;
            }
        }

        throw InvalidGeographicCoordinateError(
            {
                rawCoordinates: args,
            },
            GeographicCoordinate.name
        );
    }

    getUrlSlug() {
        return generateCoordinateIdFromCoordinatePoints(
            this.latitude,
            this.longitude
        );
    }

    getCoordinate(): GeographicCoordinate {
        return this;
    }

    getCoordinateAsLngLat(): [number, number] {
        return [this.longitude, this.latitude];
    }

    getHumanReadableStringRepresentation(): string {
        return `(${this.latitude}, ${this.longitude})`;
    }
}

const parseArrayInputIntoCoordinates = (
    ...parseArgs: any[]
): [number, number] | undefined => {
    // Check that there are right number of coordinate points provided
    if (parseArgs.length === numberOfElementsInAGeographicCoordinate) {
        // If they are numbers, assign them directly into latitude and longitude
        if (
            typeof parseArgs[0] === "number" &&
            typeof parseArgs[1] === "number"
        ) {
            const latitude = parseArgs[0];
            const longitude = parseArgs[1];

            return [latitude, longitude];
        } else if (
            typeof parseArgs[0] === "string" &&
            typeof parseArgs[1] === "string"
        ) {
            // If they are strings, parse them into floats
            const latitude = parseFloat(parseArgs[0]);
            const longitude = parseFloat(parseArgs[1]);

            if (isNaN(longitude) || isNaN(latitude)) {
                // The latitude and longitude could not be parsed into numbers
                return undefined;
            }

            return [latitude, longitude];
        } else {
            reportError(
                `Coordinate points provided were not of consistent types: (${typeof parseArgs[0]}, ${typeof parseArgs[1]})`
            );
        }
    }

    reportError(
        `${parseArgs.length} coordinate points were provided. Only ${numberOfElementsInAGeographicCoordinate} were expected.`
    );

    return undefined;
};

function generateCoordinateIdFromCoordinatePoints(
    latitude: any,
    longitude: any
) {
    return `coordinate_${latitude}_${longitude}`;
}
