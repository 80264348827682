import { buildErrorWithProps } from "@/common/application/errors/error-builders";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";

export const UnexpectedError = buildErrorWithProps({
    errorName: "UnexpectedError",
    builder: UserFacingErrorBuilder,
    build: (
        builder,
        props: {
            message: string;
        }
    ) =>
        builder
            .enableAnalytics()
            .setInternalMessage(
                `An unexpected error occurred: ${props.message}.`
            )
            .setUserFacingErrorMessage(`Something unexpected happened.`),
});
